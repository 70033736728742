import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import { SnackbarProvider } from 'notistack';
import configureStore from './store/configureStore';
import { Provider } from 'react-redux';
import { Router, Route, IndexRoute, browserHistory } from 'react-router';
import { AssetsListing } from './User';
import { Listing, HeadLines, MRRate, LeaseRentalRates, Utilization, AddEditUtils, ContractCashFlow, ContractDashboard, ViewInvoice, EditInvoice, Invoice, MRAccural, MrAccrualCashFlow } from './applications/contracts';
import InductionCondition from './lease_management/lease_details/induction_conditions/containers/';
import DeliveryCondition from './lease_management/lease_details/delivery_conditions/containers/';
import ReturnCondition from './lease_management/lease_details/return_conditions/containers/';
import QfdMntEvents from './lease_management/lease_details/qfd_mnt_events/containers/';
import SubLeases from './lease_management/lease_details/sub_leases/containers/';
import LeaseOptions from './lease_management/lease_details/lease_options/containers/';
import WorkingGroups from './lease_management/lease_details/working_groups/containers/';
import CapeTownConvention from './lease_management/lease_details/cape_town_convention/containers/';
import Obligations from './lease_management/lease_details/obligations/containers/';
import SecurityDeposits from './lease_management/lease_details/security_deposits/containers/';
import Insurances from './lease_management/lease_details/insurances/containers/'
import Parties from './lease_management/lease_details/parties/containers/';
import FinancingDetails from './lease_management/lease_details/financing_details/containers/';
import ContractListing from './lease_management/Leases/containers/';
import AssemblyListing from './lease_management/assemblyLease/container';
import MouListing from './lease_management/mou/container';
import AssmblyUtilization from './lease_management/assemblyUtilization/container';
import AssmblyInvoices from './lease_management/assemblyInvoice/container';
import AssemblyCashFlow from './lease_management/assemblyCashFlow/container';
import AssemblyDetails from './lease_management/assemblyDetails/container';
import AddMRUtilization from './lease_management/utilizations/containers/AddMRUtilization';
import AssetUtilization from './lease_management/utilizations/containers/AssetUtilization';
import InvoiceList from './lease_management/invoices/containers/';
import InvoiceView from './lease_management/invoices/containers/View';
import InvoiceEdit from './lease_management/invoices/containers/Edit';
import CashFlows from './lease_management/cashflows/containers/';
import Statistics from './lease_management/dashboards/containers/Statistics';
import FleetInvoices from './lease_management/invoices/containers/FleetInvoices';
import FleetLeases from './lease_management/Leases/containers/FleetLeases';
import FleetUtilization from './lease_management/utilizations/containers/FleetUtilization';
import FleetCashflows from './lease_management/cashflows/containers/FleetCashflow';
import {ContractsFleetDb } from './applications/dashboards';
import { PageNotFound, NoAccessApps } from './shared';
import Faqs from './shared/components/Faqs';
import FeaturesList from './shared/components/FeaturesList';
import App from './App';
import requireAuth from './utils/requireAuth';
import LoggedUserRed from './utils/LoggedUserRed';
import { Onboarding } from './phase2/auth';
import ThirdPartyLanding from './applications/contracts/Invoice/components/ThirdPartyLanding';
const store = configureStore();
ReactDOM.render(
	<div>
	<Provider store={store}>
		<Router history={browserHistory}>
			<Route path="/" component={App}>
				<IndexRoute component={requireAuth(ContractsFleetDb)}/>
				<Route path="/login" component={LoggedUserRed(Onboarding)} />
				<Route path="/assets-listing" component={requireAuth(AssetsListing)} />
				<Route path="/invoices" component={requireAuth(FleetInvoices)} />
				<Route path="/leases" component={requireAuth(FleetLeases)} />
				<Route path="/utilizations" component={requireAuth(FleetUtilization)} />
				<Route path="/cashflows" component={requireAuth(FleetCashflows)} />
				<Route path='/:type/:aircraft_slug/contracts' component={requireAuth(ContractListing)}/>
				<Route path='/:type/:aircraft_slug/mou' component={requireAuth(MouListing)}/>
				<Route path='/:type/:aircraft_slug/assembly-lease' component={requireAuth(AssemblyListing)}/>
				<Route path='/:type/:aircraft_slug/assembly-utilization/:slug' component={requireAuth(AssmblyUtilization)}/>
				<Route path='/:type/:aircraft_slug/assembly-invoices/:slug' component={requireAuth(AssmblyInvoices)}/>
				<Route path='/:type/:aircraft_slug/assembly-cashflow/:slug' component={requireAuth(AssemblyCashFlow)}/>
				<Route path='/:type/:aircraft_slug/assembly-details/:slug' component={requireAuth(AssemblyDetails)}/>
				<Route path="/fleet/contracts/dashboard" component={requireAuth(ContractsFleetDb)} />
				<Route path='/:type/:aircraft_slug/contract/list/:contract_slug' component={requireAuth(HeadLines)}/>
				<Route path='/:type/:aircraft_slug/contract/:contract_slug/working-group' component={requireAuth(WorkingGroups)}/>
				<Route path='/:type/:aircraft_slug/contract/:contract_slug/parties' component={requireAuth(Parties)}/>
				<Route path='/:type/:aircraft_slug/contract/:contract_slug/obligations' component={requireAuth(Obligations)}/>
				<Route path='/:type/:aircraft_slug/contract/:contract_slug/sub-lease' component={requireAuth(SubLeases)}/>
				<Route path='/:type/:aircraft_slug/contract/:contract_slug/insurance' component={requireAuth(Insurances)}/>
				<Route path='/:type/:aircraft_slug/contract/:contract_slug/security-deposit' component={requireAuth(SecurityDeposits)}/>
				<Route path='/:type/:aircraft_slug/contract/:contract_slug/cape-town-convention' component={requireAuth(CapeTownConvention)}/>
				<Route path='/:type/:aircraft_slug/contract/:contract_slug/lease-option' component={requireAuth(LeaseOptions)}/>
				<Route path='/:type/:aircraft_slug/contract/:contract_slug/induction-condition' component={requireAuth(InductionCondition)}/>
				<Route path='/:type/:aircraft_slug/contract/:contract_slug/delivery-condition' component={requireAuth(DeliveryCondition)}/>
				<Route path='/:type/:aircraft_slug/contract/:contract_slug/return-condition' component={requireAuth(ReturnCondition)}/>
				<Route path='/:type/:aircraft_slug/contract/:contract_slug/mr-rate' component={requireAuth(MRRate)} />
				<Route path='/:type/:aircraft_slug/contract/:contract_slug/finance-detail' component={requireAuth(FinancingDetails)} />
				<Route path='/:type/:aircraft_slug/contract/:contract_slug/lease-rental-condition' component={requireAuth(LeaseRentalRates)}/>
				<Route path='/:type/:aircraft_slug/contract/:contract_slug/qualified-maintenance' component={requireAuth(QfdMntEvents)}/>
				<Route path='/:type/:aircraft_slug/contract/:contract_slug/utilization' component={requireAuth(Utilization)} />
				<Route path='/:type/:aircraft_slug/contract/:contract_slug/utilization_new' component={requireAuth(AssetUtilization)} />
				<Route path='/:type/:aircraft_slug/contract/:contract_slug/utilization/add' component={requireAuth(AddEditUtils)} />
				<Route path='/:type/:aircraft_slug/contract/cash-flow' component={requireAuth(ContractCashFlow)}/>
				<Route path='/:type/:aircraft_slug/contract/:contract_slug/cashflow' component={requireAuth(ContractCashFlow)}/>
				<Route path='/:type/:aircraft_slug/contract/:contract_slug/cashflow_new' component={requireAuth(CashFlows)}/>
				<Route path='/:type/:aircraft_slug/contract/:contract_slug/mr-accrual' component={requireAuth(MRAccural)}/>
				<Route path='/:type/:aircraft_slug/contract/:contract_slug/mr-accrual-cash-flow' component={MrAccrualCashFlow} />
				<Route path='/:type/:aircraft_slug/contract/:contract_slug/invoice/edit/:id' component={requireAuth(EditInvoice)}/>
				<Route path='/:type/:aircraft_slug/contract/:contract_slug/invoice_new/edit/:id' component={requireAuth(InvoiceEdit)}/>
				<Route path='/:type/:aircraft_slug/contract/:contract_slug/invoice/view/:id' component={requireAuth(ViewInvoice)}/>
				<Route path='/third-party' component={requireAuth(ThirdPartyLanding)}/>
				<Route path='/:type/:aircraft_slug/contract/:contract_slug/invoice_new/view/:id' component={requireAuth(InvoiceView)}/>
		 		{/* <Route path='/:type/:aircraft_slug/contract/dashboard' component={requireAuth(ContractDashboard)}/> */}
				<Route path='/:type/:aircraft_slug/contract/:contract_slug/dashboard' component={requireAuth(ContractDashboard)}/>
				<Route path='/:type/:aircraft_slug/contract/:contract_slug/dashboard_new' component={requireAuth(Statistics)}/>
				<Route path='/:type/:aircraft_slug/contract/invoice' component={requireAuth(Invoice)}/>
				<Route path='/:type/:aircraft_slug/contract/:contract_slug/invoice' component={requireAuth(Invoice)}/>
				<Route path='/:type/:aircraft_slug/contract/:contract_slug/invoice_new' component={requireAuth(InvoiceList)}/>
				<Route path="/restricted-access/:type" component={requireAuth(NoAccessApps)} />
				<Route path="/faqs" component={requireAuth(Faqs)} />
				<Route path="/whats-new" component={requireAuth(FeaturesList)} />
			</Route>
			<Route path='*' exact={true} component={PageNotFound} />
		</Router>
	</Provider></div>,
	document.querySelector('.root')
);
